.bgWrapper {
    overflow: hidden;
    position: relative;
    .orangeCircle {
        position: absolute;
        z-index: 1;
        width: 1100px;
        top: -525px;
        left: -432px;
        animation: rotate 6.5s linear infinite;
    }
    .turquoiseStarSmall {
        position: absolute;
        width: 30px;
        top: 8.5%;
        right: 43%;
        z-index: 1;
        animation: rotate 4.5s linear infinite;
    }

    .pinkCircle {
        position: absolute;
        top: 47%;
        right: 29%;
        width: 350px;
        animation: rotate 4.5s linear infinite;
    }
    .yellowCircleFirst {
        position: absolute;
        width: 80px;
        top: 42%;
        right: 19%;
        animation: rotate 6.5s linear infinite;
    }

    .turquoiseStarMain {
        position: absolute;
        width: 430px;
        top: 22%;
        left: 10%;
        animation: rotate 8.5s linear infinite;
    }

    .yellowStarBG {
        position: absolute;
        bottom: 66px;
        left: -172px;
        width: 440px;
        z-index: 1;
        animation: rotate 8.5s linear infinite;
    }
    .yellowCircleSecond {
        position: absolute;
        width: 80px;
        bottom: 20px;
        left: 240px;
        z-index: 1;
        animation: rotate 6.5s linear infinite;
    }
}
@keyframes rotate {
    to {
        transform: rotate(360deg);
    }
}
@media (max-width: 1700px) {
    .bgWrapper {
        overflow: hidden;
        position: relative;
        .turquoiseStarMain {
            top: 22%;
            left: 2%;
        }
    }
}

@media (max-width: 991px) {
    .bgWrapper {
        overflow: hidden;
        position: relative;
        .turquoiseStarSmall {
            display: none;
        }
        .turquoiseStarMain {
            top: 30%;
            left: 24%;
            width: 400px;
        }
    }
}
@media (max-width: 700px) {
    .bgWrapper {
        overflow: hidden;
        position: relative;
        .turquoiseStarMain {
            top: 22%;
            left: 24%;
            width: 400px;
        }
    }
}
@media (max-width: 575.98px) {
    .bgWrapper {
        overflow: hidden;
        position: relative;
        .orangeCircle {
            z-index: 1;
            width: 600px;
            top: -125px;
            left: -232px;
            animation: rotate 7.5s linear infinite;
        }
    }
}

@media (max-width: 390px) {
    .bgImage {
        &:first-child {
            display: none;
        }
    }
}
