// @font-face {
//   font-family: "tajawalRegular";
//   font-style: regular;
//   src: url('../assets/fonts/Tajawal-Regular.ttf')
// }

// @font-face {
//   font-family: "tajawalBold";
//   font-style: regular;
//   src: url('../assets/fonts/Tajawal-Bold.ttf')
// }

@font-face {
  font-family: "Almarai";
  src: url("../assets/fonts/Almarai-Light.eot");
  src: url("../assets/fonts/Almarai-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Almarai-Light.woff2") format("woff2"),
    url("../assets/fonts/Almarai-Light.woff") format("woff"),
    url("../assets/fonts/Almarai-Light.ttf") format("truetype"),
    url("../assets/fonts/Almarai-Light.svg#../assets/fonts/Almarai-Light")
      format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Almarai";
  src: url("../assets/fonts/Almarai-ExtraBold.eot");
  src: url("../assets/fonts/Almarai-ExtraBold.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Almarai-ExtraBold.woff2") format("woff2"),
    url("../assets/fonts/Almarai-ExtraBold.woff") format("woff"),
    url("../assets/fonts/Almarai-ExtraBold.ttf") format("truetype"),
    url("../assets/fonts/Almarai-ExtraBold.svg#../assets/fonts/Almarai-ExtraBold")
      format("svg");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Almarai";
  src: url("../assets/fonts/Almarai-Regular.eot");
  src: url("../assets/fonts/Almarai-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Almarai-Regular.woff2") format("woff2"),
    url("../assets/fonts/Almarai-Regular.woff") format("woff"),
    url("../assets/fonts/Almarai-Regular.ttf") format("truetype"),
    url("../assets/fonts/Almarai-Regular.svg#../assets/fonts/Almarai-Regular")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Almarai";
  src: url("../assets/fonts/Almarai-Bold.eot");
  src: url("../assets/fonts/Almarai-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Almarai-Bold.woff2") format("woff2"),
    url("../assets/fonts/Almarai-Bold.woff") format("woff"),
    url("../assets/fonts/Almarai-Bold.ttf") format("truetype"),
    url("../assets/fonts/Almarai-Bold.svg#../assets/fonts/Almarai-Bold")
      format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat-BlackItalic.eot");
  src: url("../assets/fonts/Montserrat-BlackItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Montserrat-BlackItalic.woff2") format("woff2"),
    url("../assets/fonts/Montserrat-BlackItalic.woff") format("woff"),
    url("../assets/fonts/Montserrat-BlackItalic.ttf") format("truetype"),
    url("../assets/fonts/Montserrat-BlackItalic.svg#Montserrat-BlackItalic")
      format("svg");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat-Bold.eot");
  src: url("../assets/fonts/Montserrat-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Montserrat-Bold.woff2") format("woff2"),
    url("../assets/fonts/Montserrat-Bold.woff") format("woff"),
    url("../assets/fonts/Montserrat-Bold.ttf") format("truetype"),
    url("../assets/fonts/Montserrat-Bold.svg#Montserrat-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat-Black.eot");
  src: url("../assets/fonts/Montserrat-Black.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Montserrat-Black.woff2") format("woff2"),
    url("../assets/fonts/Montserrat-Black.woff") format("woff"),
    url("../assets/fonts/Montserrat-Black.ttf") format("truetype"),
    url("../assets/fonts/Montserrat-Black.svg#Montserrat-Black") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat-ExtraBoldItalic.eot");
  src: url("../assets/fonts/Montserrat-ExtraBoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Montserrat-ExtraBoldItalic.woff2") format("woff2"),
    url("../assets/fonts/Montserrat-ExtraBoldItalic.woff") format("woff"),
    url("../assets/fonts/Montserrat-ExtraBoldItalic.ttf") format("truetype"),
    url("../assets/fonts/Montserrat-ExtraBoldItalic.svg#Montserrat-ExtraBoldItalic")
      format("svg");
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat-BoldItalic.eot");
  src: url("../assets/fonts/Montserrat-BoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Montserrat-BoldItalic.woff2") format("woff2"),
    url("../assets/fonts/Montserrat-BoldItalic.woff") format("woff"),
    url("../assets/fonts/Montserrat-BoldItalic.ttf") format("truetype"),
    url("../assets/fonts/Montserrat-BoldItalic.svg#Montserrat-BoldItalic")
      format("svg");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat-MediumItalic.eot");
  src: url("../assets/fonts/Montserrat-MediumItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Montserrat-MediumItalic.woff2") format("woff2"),
    url("../assets/fonts/Montserrat-MediumItalic.woff") format("woff"),
    url("../assets/fonts/Montserrat-MediumItalic.ttf") format("truetype"),
    url("../assets/fonts/Montserrat-MediumItalic.svg#Montserrat-MediumItalic")
      format("svg");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat-Light.eot");
  src: url("../assets/fonts/Montserrat-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Montserrat-Light.woff2") format("woff2"),
    url("../assets/fonts/Montserrat-Light.woff") format("woff"),
    url("../assets/fonts/Montserrat-Light.ttf") format("truetype"),
    url("../assets/fonts/Montserrat-Light.svg#Montserrat-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat-Medium.eot");
  src: url("../assets/fonts/Montserrat-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Montserrat-Medium.woff2") format("woff2"),
    url("../assets/fonts/Montserrat-Medium.woff") format("woff"),
    url("../assets/fonts/Montserrat-Medium.ttf") format("truetype"),
    url("../assets/fonts/Montserrat-Medium.svg#Montserrat-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat-Italic.eot");
  src: url("../assets/fonts/Montserrat-Italic.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Montserrat-Italic.woff2") format("woff2"),
    url("../assets/fonts/Montserrat-Italic.woff") format("woff"),
    url("../assets/fonts/Montserrat-Italic.ttf") format("truetype"),
    url("../assets/fonts/Montserrat-Italic.svg#Montserrat-Italic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat-LightItalic.eot");
  src: url("../assets/fonts/Montserrat-LightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Montserrat-LightItalic.woff2") format("woff2"),
    url("../assets/fonts/Montserrat-LightItalic.woff") format("woff"),
    url("../assets/fonts/Montserrat-LightItalic.ttf") format("truetype"),
    url("../assets/fonts/Montserrat-LightItalic.svg#Montserrat-LightItalic")
      format("svg");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat-ExtraLight.eot");
  src: url("../assets/fonts/Montserrat-ExtraLight.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Montserrat-ExtraLight.woff2") format("woff2"),
    url("../assets/fonts/Montserrat-ExtraLight.woff") format("woff"),
    url("../assets/fonts/Montserrat-ExtraLight.ttf") format("truetype"),
    url("../assets/fonts/Montserrat-ExtraLight.svg#Montserrat-ExtraLight")
      format("svg");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat-ExtraLightItalic.eot");
  src: url("../assets/fonts/Montserrat-ExtraLightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Montserrat-ExtraLightItalic.woff2") format("woff2"),
    url("../assets/fonts/Montserrat-ExtraLightItalic.woff") format("woff"),
    url("../assets/fonts/Montserrat-ExtraLightItalic.ttf") format("truetype"),
    url("../assets/fonts/Montserrat-ExtraLightItalic.svg#Montserrat-ExtraLightItalic")
      format("svg");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat-ExtraBold.eot");
  src: url("../assets/fonts/Montserrat-ExtraBold.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Montserrat-ExtraBold.woff2") format("woff2"),
    url("../assets/fonts/Montserrat-ExtraBold.woff") format("woff"),
    url("../assets/fonts/Montserrat-ExtraBold.ttf") format("truetype"),
    url("../assets/fonts/Montserrat-ExtraBold.svg#Montserrat-ExtraBold")
      format("svg");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat-Thin.eot");
  src: url("../assets/fonts/Montserrat-Thin.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Montserrat-Thin.woff2") format("woff2"),
    url("../assets/fonts/Montserrat-Thin.woff") format("woff"),
    url("../assets/fonts/Montserrat-Thin.ttf") format("truetype"),
    url("../assets/fonts/Montserrat-Thin.svg#Montserrat-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat-SemiBold.eot");
  src: url("../assets/fonts/Montserrat-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Montserrat-SemiBold.woff2") format("woff2"),
    url("../assets/fonts/Montserrat-SemiBold.woff") format("woff"),
    url("../assets/fonts/Montserrat-SemiBold.ttf") format("truetype"),
    url("../assets/fonts/Montserrat-SemiBold.svg#Montserrat-SemiBold")
      format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat-SemiBoldItalic.eot");
  src: url("../assets/fonts/Montserrat-SemiBoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Montserrat-SemiBoldItalic.woff2") format("woff2"),
    url("../assets/fonts/Montserrat-SemiBoldItalic.woff") format("woff"),
    url("../assets/fonts/Montserrat-SemiBoldItalic.ttf") format("truetype"),
    url("../assets/fonts/Montserrat-SemiBoldItalic.svg#Montserrat-SemiBoldItalic")
      format("svg");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat-Regular.eot");
  src: url("../assets/fonts/Montserrat-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Montserrat-Regular.woff2") format("woff2"),
    url("../assets/fonts/Montserrat-Regular.woff") format("woff"),
    url("../assets/fonts/Montserrat-Regular.ttf") format("truetype"),
    url("../assets/fonts/Montserrat-Regular.svg#Montserrat-Regular")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat-ThinItalic.eot");
  src: url("../assets/fonts/Montserrat-ThinItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Montserrat-ThinItalic.woff2") format("woff2"),
    url("../assets/fonts/Montserrat-ThinItalic.woff") format("woff"),
    url("../assets/fonts/Montserrat-ThinItalic.ttf") format("truetype"),
    url("../assets/fonts/Montserrat-ThinItalic.svg#Montserrat-ThinItalic")
      format("svg");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "NeoSansArabicMedium";
  src: url("../assets/fonts/NeoSansArabicMedium.ttf") format("truetype");
  font-weight: medium;
}

@font-face {
  font-family: "NeoSansArabicRegular";
  src: url("../assets/fonts/NeoSansArabicRegular.ttf") format("truetype");
  font-weight: normal;
}