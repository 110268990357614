$nero: #252525;
$blue-bell: #9495c8;
$corn-flower: #504f78;
$snow: #fbfbfb;
$crimson: #d51e46;
$paua: #2c2550;
$white: #ffffff;
$transaprent-white: #ffffffbd;
$shamrock: #30d881;
$claret: #710f34;
$saltpan: #f3f4f2;
$black: #000000;
$aluminium: #818a91;
$montana: #373a3c;
$panache: #e7e9e6;
$alice-blue: #eceeef;
$link-water: #ced4da;
$solitude: #f1f4f9;
$lavender: #e7e7f1;
$cherry-pie: #34304b;
$spun-pearl: #aaa9bb;
$white-ice: #d1f4e3;
$link-water: #ced4da;
$blue-color: #3bbeff;
$sundown: #ffb4b4;
$red-violet: #a32383;
$lilac: #d290c2;
$fountain-blue: #52b1b5;
$summer-sky: #3abeff;
$yellow: #ffd800;
$supernova: #f1ca00;
$ghost: #c7c6d0;
$slateblue: #6558dd;
$iris-blue: #00acc1;
$sea-serpent: #57bec5;
$red-gradient: linear-gradient(262deg, #d61e46 0%, #720f34 100%);
$purple-gradient: transparent linear-gradient(0deg, #290552 0%, #13010800 100%) 10% 0% no-repeat padding-box;
$black-gradient: transparent linear-gradient(0deg, #000000 0%, #00000000 100%) 10% 0% no-repeat padding-box;
$gray: #6c757d;
$darkslategray: #323a46;
$new-green: #5ccab2;
$new-orange: #eb5027;
$jest: #e77a29;
$new-violet: #8b3ff5;
$hop-bush: #c464a2;
$light-grey: #dcdcdc;
$whitesmoke: #f1f1f1;
$silver: #c4c4c4;
$lightblue: #a5ddd1;
$monte-carlo: #87d0d5;
$moccasin: #fcf3b1;
$gold: #f6d700;
$drover: #fcf3b1;
$drover-dark: #f6d700;
$green: #5ccab280;
$turquoise: rgb(209, 241, 234);
$turquoise-dark: rgb(85, 138, 128);
$turquoise-light: rgb(203, 237, 230);
$silver-chalice: #a9a9a9;
// $tajawal-regular: "tajawalRegular";
// $tajawal-bold: "tajawalBold";
