@import "src/styles/normalize";
@import "src/styles/fonts.scss";
@import "src/styles/variables.scss";
@import "src/styles/mixins";

* {
  font-family: "Montserrat";
}

.portalOnly {
  & * {
    font-family: "NeoSansArabicRegular";
    color: $black;
  }
}

p.text-danger.form-field-error {
  font-size: 12px !important;
  color: $jest !important;
}

.react-datepicker-wrapper {
  width: 300px;
}

input.datepicker {
  width: 100%;
  height: 37px;
  border-radius: 3px;
  border: 1px solid $link-water;
}

button {
  outline: none !important;
}

body {
  overflow-x: hidden;
}

p {
  margin-bottom: 0;
}

.mr-10 {
  margin-right: 10px;
}

.pt-100 {
  padding-top: 100px;
}

.mt1rem {
  margin-top: 1rem;
}

.w90 {
  width: 90%;
}

.jcc {
  display: flex;
  justify-content: center;
}

.jce {
  display: flex;
  justify-content: flex-end;
}

.requiredField {
  position: relative;
  padding-left: 10px;

  &::before {
    content: "*";
    height: 100%;
    @include display-flex($align: center);
    @include font-style($color: $jest);
    @include position($position: absolute, $left: 0px);
  }
}

.invalidField {
  border: 2px solid $jest !important;
  padding-top: 4px !important;
}

.successField {
  border: 1px solid $sea-serpent !important;
}

.errorMessage {
  color: $jest;
  align-self: flex-start;
  margin-top: 10px;
}

.mL {
  margin-left: 300px;
}

button.btn-paua {
  background-color: $black;
}

button.btn-gray {
  background-color: $gray;
}

button.btn-list {
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  color: $white;
  margin: 0 5px;
  height: 33px;
}

.adminDeleteModal {
  .modal-content {
    border-radius: 2px;
    padding: 20px;

    .close {
      position: absolute;
      right: 0;
      top: 0;
      padding-top: 0px;
      padding-right: 0px;
      margin: 20px;

      span {
        font-size: 30px;
      }
    }
  }
}

.successAlert {
  width: 100%;
  min-height: 30px;
  background: $turquoise;
  border: $turquoise-light;
  color: $turquoise-dark;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px 20px;
}

.admin-submit-btn {
  background-color: $new-green !important;
  border: none !important;
  transition: 0.5s background-color;

  &:hover {
    background-color: $darkslategray !important;
  }
}

.admin-submit-btn-black {
  background-color: $black !important;
  border: none !important;
}

.ml-20 {
  margin-left: 20px;
}

.mr-20 {
  margin-right: 20px;
}

[dir="rtl"] {
  * {
    font-family: "Almarai";
  }

  .requiredField {
    padding-left: auto;
    padding-right: 10px;

    &::before {
      left: auto;
      right: 0;
    }
  }

  .beta-v {
    &::after {
      top: 28px;
      transform: rotate(45deg);
      right: -60px;
    }
  }
}

@for $i from 0 through 100 {
  .wper-#{$i} {
    width: round(percentage($i/100));
  }
}

.status {
  &CommingSoon {
    color: #8b3ff5;
  }

  &Open {
    color: $shamrock;
  }

  &Close {
    color: #eb5027;
  }

  &Cancelled {
    color: $crimson;
  }
}

#user_type {
  background-color: $panache;
}
