#multiselectContainerReact {
    .optionListContainer .optionContainer {
        li:hover  {
            background-color: $gray-700
        }
        .highlight {
            background-color: $gray-700
        }
    } 
    .chip {
        background-color: $gray-700
    }
}

