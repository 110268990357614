.btn-primary {
  color: #fff;
  background-color: $primary;
  border-color: $primary;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:focus {
  color: #fff;
  background-color: $grey-blue;
  border-color: $grey-blue;
}
.btn-primary:focus,
.btn-primary.active {
  box-shadow: 0 0 0 0.15rem rgba(108, 117, 125, 0.5);
}
