//
// authentication.scss
//

.authentication-bg.enlarged {
  min-height: 100px;
  height: 100vh;
}

// authentication pages background
.authentication-bg {
  background-color: $gray-200;
  background-size: cover;
  background-position: center;
  // padding-bottom: 60px;
  overflow-x: hidden;
}

.authentication-bg-pattern {
  // background-image: url("../../../images/bg-pattern.png");
  background-color: #003268;
}

// Logout page
.logout-icon {
  width: 140px;
}
